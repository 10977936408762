import React from "react";
import "./HomeBanners.css";
import Anker from "../../assets/Anker.png";
import Baseus from "../../assets/Baseus.png";

const HomeBanners = () => {
  return (
    <div className="banner-container">
      <div className="banner-card">
        <a className="block-hero" href="/brand/7">
          <img
            alt="banner"
            src="https://images.macrumors.com/t/t9uc5aZgTY8fGdf9410heyikTZc=/1200x1200/smart/article-new/2021/09/anker-nano-pro-colors.jpg"
          />
          <div className="banner-info">
            <img src={Anker} alt="product-name" className="Logo" />
          </div>
        </a>
      </div>

      {/* card2 */}
      <div className="banner-card">
        <a className="block-hero" href="/brand/3">
          <img
            alt="banner"
            src="https://cdn.shopify.com/s/files/1/0295/2036/3658/products/1_91cf2436-784b-4192-baa6-07fe03c13851_1600x.jpg?v=1650866174"
          />

          <div className="banner-info">
            <img src={Baseus} alt="product-name" className="Logo" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default HomeBanners;
