import React, { useEffect, useState } from "react";
import apiClient from "./../../api/client";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import tiktok from "../../assets/tiktok.png"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import "./Footer.css";

const Footer = () => {
  // const [brand, setBrand] = useState(true);
  // const fetchBrandDetailById = async () => {
  //   const { data } = await apiClient.get("/variations/getbrand");
  //   setBrand(data);
  //   console.log(data)
  // };
  // useEffect(() => {
  //   fetchBrandDetailById();
  // }, []);
  return (
    <div className="footermain">
      <div className="footer">
      
        <ul>
          <p>My Wasel</p>
          <li>
            <a href="/login">Login</a>
          </li>
          <li>
            <a href="/myorders">My Orders</a>
          </li>
          <li>
            <a href="/register">Subscribe</a>
          </li>
        </ul>
        <ul>
          <p>Collections</p>
          <li>
            <a href="/brand/6">Xiomi</a>
          </li>
          <li>
            <a href="/brand/7">Anker</a>
          </li>

          <li>
            <a href="/brand/4">Green Lion</a>
          </li>
          <li>
            <a href="/brand/20">Powerlogy</a>
          </li>
          <li>
            <a href="/brand/5">Porodo</a>
          </li>
          <li>
            <a href="/brand/3">Baseus</a>
          </li>
        </ul>
        <ul>
          <p>Company</p>
          <li>
            <a href="/aboutus">About Us</a>
          </li>
          <li>
            <a href="/contactus">Contact Us</a>
          </li>
          <li>
            <a href="/privacypolicy">Privacy Policy</a>
          </li>
          <li>
            <a href="/termsandcondition">Terms and Conditions</a>
          </li>
          <li>
            <a href="/returnpolicy">Return Policy</a>
          </li>
        </ul>
        <div className="social-media">
        <a style={{ paddingRight: "12px"}} href="https://www.facebook.com/wasel.qa"><FacebookOutlinedIcon/></a>
      <a style={{ paddingRight: "13px"}} href="https://www.instagram.com/wasel.qa/?igshid=YmMyMTA2M2Y%3D"><InstagramIcon/></a>
      <a href="https://wa.me/+97450301166" target="_blank">
          <WhatsAppIcon />
        </a>
      <a className="tiktok" href="https://vm.tiktok.com/ZS8XRaRpy/"><img alt="tiktok" src={tiktok} height={20}/></a>
    

        </div>
      </div>
 
      <div className="copyright">
        <p className="copyright-1">
       
          Copyright © 2022
          <span style={{ fontWeight: 600, marginLeft: "5px" }}>Wasel</span>. All
          rights reserved. 
        </p>
        <p>
         
          
        </p>
      </div>
    </div>
  );
};

export default Footer;
