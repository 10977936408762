import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import apiClient from "../../api/client";
import { mobile } from "../../responsive";

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [sliderItems, setSliderItems] = useState();
  const [index, setIndex] = useState(0);

  const handleClick = (direction) => {
    if (direction === "left") {
      setIndex(index > 0 ? index - 1 : sliderItems?.length - 1);
    } else {
      setIndex(index < sliderItems?.length - 1 ? index + 1 : 0);
    }
  };

  const caraousel = () => {
    if (index === sliderItems?.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  useEffect(() => {
    fetchBanners();
    const interval = setInterval(() => {
      caraousel();
    }, 4000);

    return () => clearInterval(interval);
  }, [index]);

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    const { data } = await apiClient.get("/variations/imgs-banner");
  
    setSliderItems(data);
  };

  return (
    <Container>
      <Arrow direction="left" onClick={() => handleClick("left")}>
        <ArrowLeftOutlined />
      </Arrow>
      <Wrapper slideIndex={slideIndex}>
        {sliderItems?.map((item) => (
          <Slide
            key={item._id}
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
          >
            <ImgContainer>
              <Image src={item.img} />
            </ImgContainer>
          </Slide>
        ))}
      </Wrapper>
      <Arrow direction="right" onClick={() => handleClick("right")}>
        <ArrowRightOutlined />
      </Arrow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 75vh;
  display: flex;
  position: relative;
  overflow: hidden;
  margin: auto;
  margin-top: 60px;
  ${mobile({ width: "100%", height: "20vh", objectFit: "contain" })}
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slide = styled.div`
  width: 100vw;
  height: 512px;
  display: flex;
  align-items: center;
  transition: ease 2000ms;
  aspect-ratio: auto 1800 / 540;
`;

const ImgContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
`;

const Image = styled.img`
  width: 99%;
  object-fit: contain;
  ${mobile({ width: "100%", height: "auto", objectFit: "contain" })}
`;

export default Slider;
