import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicyEnglish = () => {
  return (
    <div className="container">
      <h2>Privacy Policy</h2>
      <p style={{ textAlign: "justify" }}>
        All packaging reflects the same distinctive styling and quality as the
        item that you have selected. Concerning shipments of merchandise to
        consumers, wherever they may reside, the title to the merchandise shall
        pass to the buyer upon delivery of the merchandise to the common
        carrier. Nothing on this site grants to you any license or right to use
        any Trademark displayed on this site without the prior written
        permission of the owner of the Trademark. Your use or misuse of any
        Trademark displayed on this site or any other content on this site is
        strictly prohibited. If a pricing error were to occur there was no
        attempt to unlawfully harm any customer. If an error is made we will try
        to correct it as quickly as possible. According to standard law
        advertising when an order is requested online is not a binding agreement
        according to the court system. It is never our intent to advertise with
        incorrect pricing. Unfortunately, mistakes can and do happen. Until an
        order is shipped a contract has not been entered into Data about the
        activity of visitors to this site is automatically provided to Wasel
        when you sign on. We use it for internal review in aggregate and
        non-personally identifiable forms. E-mail addresses and other personally
        identifying data about you are known to Wasel only when voluntarily
        submitted or posted to us. In addition, we sometimes use E-mail
        addresses and other personally identifying information to contact
        visitors who communicate with us we also use the shipping addresses of
        our customers to send them our catalogs which we feel will be of
        interest to them.
      </p>
      <h3>Using Personal Information</h3>
      <p style={{ textAlign: "justify" }}>
        We use your personal Information to provide our services to you, which
        includes: offering products for sale, processing payments, shipping and
        fulfillment of your order, and keeping you up to date on new products,
        services, and offers.
      </p>
    </div>
  );
};

export default PrivacyPolicyEnglish;
