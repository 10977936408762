import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import HeaderArabic from "../Header/HeaderArabic";
import ProfileArabic from "./ProfileArabic";
import ProfileEnglish from "./ProfileEnglish";

const ProductDetail = () => {
  const [lang, setLang] = useState("");

  useEffect(() => {
    setLang(sessionStorage.getItem("language"));
  }, []);

  return (
    <div>
      {lang === "ar" ? (
        <>
          <HeaderArabic />
          <ProfileArabic />
        </>
      ) : (
        <>
          <Header />
          <ProfileEnglish />
        </>
      )}
    </div>
  );
};

export default ProductDetail;
