import React, { useState } from "react";
import Card from "../Card/Card";
import styles from "./Products.module.css";

const Products = ({ products }) => {
  return (
    <div className={styles.productscontainer}>
      <div className={styles.productswrapper}>
        <ul>
          {products?.map((item) => {
            return <Card product={item} />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default Products;
