import React, { useState, useEffect } from "react";
import "./ProductDetail.css";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import Carousel from "react-material-ui-carousel";
import { useDispatch } from "react-redux";
import { add } from "../../redux/cartSlice";
import { Add, Remove } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "./../../auth/useAuth";
import Rating from "@mui/material/Rating";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "./../../api/client";
import ReviewCard from "./ReviewCard";
import SyncLoader from "react-spinners/SyncLoader";
import ReactPlayer from "react-player";
import styled from "styled-components";

const ProductDetailArabic = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [products, setProducts] = useState();
  const [uploadVisible, setUploadVisible] = useState(false);
  const [data, setData] = useState();
  const [size, setSize] = useState("");
  const [sizes, setSizes] = useState();

  //colors
  const [color, setColor] = useState("");
  const [colors, setColors] = useState();

  const { user } = useAuth();

  //product review comment
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    fetchProducts();
  }, []);

  let { id } = useParams();
  const groupId = product?.groupId;
  const fetchProducts = async () => {
    setUploadVisible(true);
    if (groupId) {
      const { data } = await apiClient.get("/products/get-productby-groupid", {
        groupId,
      });

      setProducts(data);
      setData(product);
      let siz1;
      let col1;
      const col = await apiClient.get("/variations/getcolor");
      const siz = await apiClient.get("/variations/getsize");
      if (product?.color?._id) {
        col1 = product?.color;
        setColor(col1?.nameAR);
      } else {
        col1 = col?.data?.filter(function (el) {
          return el._id === product?.color;
        });
        setColor(col1[0]?.nameAR);
      }
      if(product?.size?._id){
        siz1 = product?.size;
        setSize(siz1?.nameAR);
      } else {
        siz1 = siz?.data?.filter(function (el) {
          return el._id === product?.size;
        });
        setSize(siz1[0]?.nameAR);
      }
      const uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
     
      var allColors = data?.map((item) => {
        return { label: item?.color?.nameAR, value: item?.color?.hexcode };
      });

      allColors = uniqueArray(allColors)
      setColors(allColors);
   

      var allSizes = data?.map((item) => {
        return item?.size?.nameAR;
      });
      allSizes = [...new Set(allSizes)];
      setSizes(allSizes);
    

      setUploadVisible(false);
    } else {
      const { data } = await apiClient.get("/products/get-productby-groupid", {
        groupId: id,
      });
      setProducts(data);
      setData(data[0]);
      const uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
     
      var AllColors = data?.map((item) => {
        return { label: item?.color?.nameAR, value: item?.color?.hexcode };
      });

      AllColors = uniqueArray(AllColors)
      setColors(AllColors);
      setColor(data[0]?.color?.nameAR);

      var allSizes = data?.map((item) => {
        return item?.size?.nameAR;
      });
      allSizes = [...new Set(allSizes)];
      setSizes(allSizes);
      setSize(data[0]?.size?.nameAR);

      setUploadVisible(false);
    }
  };

  const handleSubmit = () => {
    dispatch(add({ product: data, quantity }));
    navigate("/cart");
  };

  const submitReviewToggle = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const handleQuantity = (type) => {
    if (type === "dec") {
      quantity > 1 && setQuantity(quantity - 1);
    } else {
      if (data.countInStock > quantity) setQuantity(quantity + 1);
    }
  };

  const new_price =
    data?.sell_price - (data?.discount / 100) * data?.sell_price;
  const reviewSubmitHandler = async () => {
    const result = await apiClient.post("/products/create-review", {
      productId: data?._id,
      rating,
      comment,
      user,
    });
    if (result.ok) {
      swal("Review Submitted");
    }
    setOpen(false);
    window.location.reload();
  };

  const handleChangeSize = (e) => {
    setSize(e.target.value);
    const xyz = e.target.value;

    const product1 = products?.filter(function (el) {
      return el.size?.nameAR === xyz && el.color?.nameAR === color;
    });
    if (product1?.length > 0) {
      setData(product1[0]);
    } else {
      setData(null);
    }
  };

  const handleChangeColors = (el) => {
    // setColor(e.target.value);
    setColor(el);
    const xyz = el;
    const product1 = products?.filter(function (el) {
      return el.color?.nameAR === xyz && el.size?.nameAR === size;
    });

    if (product1?.length > 0) {
      setData(product1[0]);
    } else {
      setData(null);
    }
  };
 
  return (
    <div>
      {uploadVisible ? (
        <div
          style={{
            display: "flex",
            margin: "300px auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SyncLoader size={30} color="rgba(254, 80, 0, 1)" />
        </div>
      ) : (
        <div className="productdetail-main-arabic">
          <div className="wrapper1">
            <div className="detail-main">
              <div className="detail-main-wrapper">
                <div className="product-title">
                  {data ? <h1>{data?.nameAR}</h1> : <h1>غير متاح  {size} {color}</h1>}
                </div>
                <div className="product-sku">
                  <p>
                    {data?.color?.nameAR}| {data?.size?.nameAR}
                  </p>
                  <p>{data?.brand?.nameAR}</p>
                </div>
                <div className="productImageMobileabc">
                  <Carousel>
                    {data?.image &&
                      data?.image.map((item, i) => (
                        <img
                          className="CarouselImageMobile"
                          key={i}
                          src={item}
                          alt={`${i} Slide`}
                        />
                      ))}
                  </Carousel>
                </div>
                <div className="product-price">
                  {data?.discount <= 0 && (
                    <p>
                      {data?.sell_price} <span>QAR</span>
                    </p>
                  )}
                  {data?.discount > 0 && (
                    <p>
                      {new_price} <span>QAR</span>
                    </p>
                  )}
                  {data?.discount > 0 && (
                    <p className="old-price" style={{ marginRight: "10px" }}>
                      {data?.sell_price} QAR
                    </p>
                  )}
                </div>
                <div className="product-amount-container">
                  <Remove onClick={() => handleQuantity("dec")} />
                  <span>{quantity}</span>
                  <Add onClick={() => handleQuantity("inc")} />
                </div>
                  {colors?.length > 0 && colors[0] !== undefined && (
                <div>
                  <p>لون: {data?.color?.nameAR}</p>

                    <Filter>
                      {colors?.map((item) => (
                        <FilterColor
                          value={item?.label}
                          color={item?.value}
                          key={item?.label}
                          onClick={() => handleChangeColors(item?.label)}
                        />
                      ))}
                    </Filter>
                </div>
                  )}

                  {sizes?.length > 0 && sizes[0] !== undefined && (
                <FilterContainer>
                  <FilterTitle>مقاس</FilterTitle>
                    <>
                      <FilterSize onChange={handleChangeSize}>
                        {sizes?.map((item) => {
                          return (
                            <FilterSizeOption value={item} key={item}>
                              {item}
                            </FilterSizeOption>
                          );
                        })}
                      </FilterSize>
                    </>
                </FilterContainer>
                  )}
                <div className="product-btns">
                  {data?.countInStock > 0 ? (
                    <button
                      className="addtocart"
                      onClick={() => handleSubmit()}
                    >
                      <LocalMallOutlinedIcon className="svg" />
                      <span>اضف إلى السلة</span>
                    </button>
                  ) : (
                    <button className="addtocart">
                      <LocalMallOutlinedIcon className="svg" />
                      <span>إنتهى من المخزن</span>
                    </button>
                  )}
                </div>
                <div className="product-options">
                  <div className="payment">
                    <strong>طريقة الدفع</strong>
                    <p>
                      الدفع عبر الإنترنت أو عند التسليم ، آمن ومضمون باستخدام أي
                      بطاقة ائتمان فيزا أو ماستر كارد
                    </p>
                    <div className="icons">
                      <p>Visa</p>
                      <p>Mastercard</p>
                    </div>
                  </div>
                  <div className="payment">
                    <strong>شحن</strong>
                    <p>
                      .شحن شحن سريع مجاني مع أفضل شركات الشحن في أقصر وقت ممكن
                    </p>
                    <div className="icons">
                      <p>Air</p>
                      <p>Ups</p>
                    </div>
                  </div>
                  <div className="payment">
                    <strong>Support</strong>
                    <p>
                      الدعم نحن متاحون بنقرة واحدة فقط ، عند اختيارك لمنتجاتك أو
                      استخدامها
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="image-main">
              <Carousel>
                {data?.image &&
                  data?.image.map((item, i) => (
                    <img
                      className="CarouselImageDesktopArabic"
                      key={i}
                      src={item}
                      alt={`${i} Slide`}
                    />
                  ))}
              </Carousel>
            </div>
          </div>

          <div className="productData">
            {data?.video && (
              <div className="productVideoWrapper">
                <div class="ProductVideoTitle">فيديو المنتج</div>
                <ReactPlayer url={data?.video} width="100%" height="100%" />
              </div>
            )}
            <div className="productDescription">
              <div className="productDescriptionTitle">وصف</div>
              <div className="productDescriptionContent">
                <p>{data?.descriptionAR}</p>
              </div>
            </div>
            <div className="productSpecs">
              <div class="ProductSpecsTitle">سمات</div>
              <div class="ProductSpecsContent">
                <ul>
                  {data?.detailsAR.map((item) => {
                    return (
                      <li>
                        <p>
                          {/* <strong>Detail: </strong> */}
                          <span>{item.nameAR} </span>
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="ProductReviews">
              <div className="ProductReviewsTitle">المراجعات</div>
              <div className="ProductReviewsContent">
                {!user ? (
                  <div className="login-register">
                    <p>
                      يجب ان تكون <a href="/login">تسجيل الدخول</a> لتقديم
                      مراجعة
                    </p>
                  </div>
                ) : (
                  <div className="login-register">
                    <p>
                      <a onClick={submitReviewToggle}>إرسال المراجعة</a>
                    </p>
                  </div>
                )}
                <Dialog
                  aria-labelledby="simple-dialog-title"
                  open={open}
                  onClose={submitReviewToggle}
                >
                  <DialogTitle>إرسال المراجعة</DialogTitle>
                  <DialogContent className="submitDialog">
                    <Rating
                      onChange={(e) => setRating(e.target.value)}
                      value={rating}
                      size="large"
                    />

                    <textarea
                      className="submitDialogTextArea"
                      cols="30"
                      rows="5"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={submitReviewToggle} color="secondary">
                      يلغي
                    </Button>
                    <Button color="primary" onClick={reviewSubmitHandler}>
                      يُقدِّم
                    </Button>
                  </DialogActions>
                </Dialog>
                <div className="ReviewsList">
                  {data?.reviews && data?.reviews[0] ? (
                    <div className="reviews">
                      {data?.reviews &&
                        data?.reviews.map((review) => (
                          <ReviewCard key={review._id} review={review} />
                        ))}
                    </div>
                  ) : (
                    <p className="noReviews">لا توجد تعليقات حتى الآن</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const FilterColor = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid black;
  background-color: ${(props) => props.color};
  margin: 0px 5px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const FilterContainer = styled.div``;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const FilterTitle = styled.span`
  font-size: 20px;
  font-weight: 200;
`;

const FilterSize = styled.select`
  padding: 5px;
  margin-left: 10px;
`;

const FilterSizeOption = styled.option``;

export default ProductDetailArabic;
