import React, { useEffect, useRef, useState } from "react";
import "./Categories.css";
import apiClient from "./../../api/client";
import { useNavigate } from "react-router-dom";

const CategoriesArabic = () => {
  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling

  const scrl = useRef(0);

  const [data, setData] = useState();

  const navigate = useNavigate();

  const fetchData = async () => {
    const { data } = await apiClient.get("/variations/get-categories");
    setData(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    //Check width of the scollings
    if (
      scrl.current &&
      scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
    return () => {};
  }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift); // Updates the latest scrolled postion

    //For checking if the scroll has ended
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <div className="container-arabic-slider">
      <div className="header-arabic">
        <strong>فئات المنتجات</strong>
      </div>
      <div className="slider">
        {!scrolEnd && (
          <button className="next" onClick={() => slide(+130)}>
            <i className="fa fa-angle-left"></i>
          </button>
        )}

        <ul className="list-arabic" ref={scrl} onScroll={scrollCheck}>
          {data?.map((item) => {
            return (
              <li onClick={() => navigate("/category", { state: item })}>
                <a>
                  <img src={item?.photo} />
                  <span className="category-span">{item?.nameAR}</span>
                </a>
              </li>
            );
          })}
        </ul>
        {scrollX !== 0 && (
          <button className="prev" onClick={() => slide(-120)}>
            <i className="fa fa-angle-right"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default CategoriesArabic;
