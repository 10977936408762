import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import "./ProductDetail.css";
import Footer from "./../Footer/Footer";
import ProductDetailArabic from "./ProductDetailArabic";
import ProductDetailEnglish from "./ProductDetailEnglish";
import HeaderArabic from "../Header/HeaderArabic";
import FooterArabic from "../Footer/FooterArabic";
import { useLocation } from "react-router-dom";

const ProductDetail = () => {
  const [lang, setLang] = useState("");
  const { state } = useLocation();

  useEffect(() => {
    setLang(sessionStorage.getItem("language"));
  }, []);
  return (
    <div>
      {lang === "ar" ? (
        <>
          <HeaderArabic />
          <ProductDetailArabic product={state} />
          <FooterArabic />
        </>
      ) : (
        <>
          <Header />
          <ProductDetailEnglish product={state} />
          <Footer />
        </>
      )}
    </div>
  );
};

export default ProductDetail;
