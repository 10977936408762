import React, { useState, useEffect } from "react";

import Header from "../Header/Header";
import Footer from "./../Footer/Footer";
import HeaderArabic from "../Header/HeaderArabic";
import FooterArabic from "../Footer/FooterArabic";
import BrandEnglish from "./BrandEnglish";
import { useParams } from "react-router-dom";
import BrandArabic from "./BrandArabic";

function Brand() {
  const [lang, setLang] = useState("");

  useEffect(() => {
    setLang(sessionStorage.getItem("language"));
  }, []);

  let { id } = useParams();

  return (
    <div>
      <div>
        {lang === "ar" ? (
          <>
            <HeaderArabic />
            <BrandArabic brandId={id} />
            <FooterArabic />
          </>
        ) : (
          <>
            <Header />
            <BrandEnglish brandId={id} />
            <Footer />
          </>
        )}
      </div>
    </div>
  );
}

export default Brand;
