import React from "react";
import "../AboutUs/AboutUs.css";
// import "./AboutUs.css";

const ContactUsArabic = () => {
  return (
    <div className="container-arabic">
      <h2 style={{ textAlign: "center", marginBottom: "30px" }}>اتصل بنا</h2>
      <p>Email: wasel.qa@gmail.com</p>
      <p>Address: Building number 326 street 280  Office no 2A 1st floor al furousiya Doha Qatar</p>
      <p>Mobile: +974-50301166</p>
    </div>
  );
};

export default ContactUsArabic;
