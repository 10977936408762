import React, { useEffect, useState } from "react";
import Header from "../Header/Header";

import Footer from "./../Footer/Footer";

import HeaderArabic from "../Header/HeaderArabic";
import FooterArabic from "../Footer/FooterArabic";

import ConfirmOrderEnglish from "./ConfirmOrderEnglish";
import ConfirmOrderArabic from "./ConfirmOrderArabic";
import { useSelector } from "react-redux";

const ProductDetail = () => {
  const [lang, setLang] = useState("");
  const items = useSelector((state) => state.cart.cart);

  useEffect(() => {
    setLang(sessionStorage.getItem("language"));
  }, []);

  return (
    <div>
      {lang === "ar" ? (
        <>
          <HeaderArabic />
          {items?.length > 0 ? (
            <ConfirmOrderArabic />
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  margin: "300px 0",
                  textAlign: "center",
                  flexDirection: "column",
                }}
              >
                <h1>الصفحة غير موجودة</h1>
                <a href="/">اذهب للمنزل</a>
              </div>
            </div>
          )}
          <FooterArabic />
        </>
      ) : (
        <>
          <Header />
          {items?.length > 0 ? (
            <ConfirmOrderEnglish />
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  margin: "300px 0",
                  textAlign: "center",
                  flexDirection: "column",
                }}
              >
                <h1>Page not found</h1>
                <a href="/">Go home</a>
              </div>
            </div>
          )}

          <Footer />
        </>
      )}
    </div>
  );
};

export default ProductDetail;
