import React, { useEffect, useState } from "react";
import Card from "../Card/Card";
import styles from "./CategoryScreenProducts.module.css";
import { Slider, Typography } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import FilterListIcon from "@mui/icons-material/FilterList";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "@mui/material";
import apiClient from "./../../api/client";
import SyncLoader from "react-spinners/SyncLoader";

const customStyles = {
  content: {
    top: "65%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    height: "100vh",
    width: "100%",
    paddingBottom: "500px",
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
};
const customStyles2 = {
  content: {
    top: "57%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "100vh",
    width: "40%",
    paddingBottom: "100px",
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
};

const CategoryScreenProducts = ({ props }) => {
  const categoryId = props?.categoryId;
  const SubCatId = props?.SubCatId;

  const mobileScreen = useMediaQuery({ query: `(max-width: 600px)` });

  const [price, setPrice] = useState([0, 2500]);
  const [sizes, setSizes] = useState();
  const [colors, setColors] = useState();
  const [brands, setBrands] = useState();
  const [size, setSize] = useState();
  const [color, setColor] = useState();
  const [brand, setBrand] = useState();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(true);

  const fetchProducts = async () => {
    const { data } = await apiClient.get("/products", {
      category: categoryId,
      subcategory: SubCatId,
      pageNumber: currentPage,
      size: size?._id,
      color: color?._id,
      brand: brand?._id,
      price: price,
    });
    setProducts(data?.products);

    setPageSize(data?.pageCount);
    setLoading(false);
  };

  useEffect(() => {
    fetchProducts();
  }, [categoryId, SubCatId, currentPage, size, color, brand]);

  useEffect(() => {
    fetchColors();
    fetchBrands();
    fetchSizes();
  }, []);

  const fetchColors = async () => {
    const { data } = await apiClient.get("/variations/getcolor");

    setColors(data);
  };
  const fetchBrands = async () => {
    const { data } = await apiClient.get("/variations/getbrand");

    setBrands(data);
  };
  const fetchSizes = async () => {
    const { data } = await apiClient.get("/variations/getsize");

    setSizes(data);
  };

  const [expanded, setExpanded] = useState(false);
  const dataForDisplay = expanded ? brands : brands?.slice(0, 10);

  const priceHandler = (event, newPrice) => {
    setPrice(newPrice);
  };
  const priceCallback = (event, newPrice) => {
    fetchProducts();
  };

  return (
    <div className={styles.productscontainer}>
      <Modal
        isOpen={modal2}
        onRequestClose={() => setModal2(false)}
        style={customStyles2}
      >
        <CloseIcon onClick={() => setModal2(false)} />
        <div className={styles.filterBox}>
          <Typography style={{ fontWeight: "600" }}>Price</Typography>
          <Slider
            value={price}
            onChange={priceHandler}
            onChangeCommitted={priceCallback}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            min={0}
            max={2500}
          />
          <Typography style={{ fontWeight: "600" }}>Sizes</Typography>
          <ul className={styles.categoryBox}>
            {sizes?.map((item) => {
              if (size?._id === item._id) {
                return (
                  <li
                    className={styles.categorylinkhigh}
                    key={item._id}
                    onClick={() => setSize(item)}
                  >
                    {item.nameEN}
                  </li>
                );
              } else {
                return (
                  <li
                    className={styles.categorylink}
                    key={item._id}
                    onClick={() => setSize(item)}
                  >
                    {item.nameEN}
                  </li>
                );
              }
            })}
          </ul>
          <Typography style={{ fontWeight: "600" }}>Colors</Typography>
          <ul className={styles.categoryBox}>
            {colors?.map((item) => {
              if (color?._id === item._id) {
                return (
                  <li
                    className={styles.categorylinkhigh}
                    key={item._id}
                    onClick={() => setColor(item)}
                  >
                    {item.nameEN}
                  </li>
                );
              } else {
                return (
                  <li
                    className={styles.categorylink}
                    key={item._id}
                    onClick={() => setColor(item)}
                  >
                    {item.nameEN}
                  </li>
                );
              }
            })}
          </ul>
          <Typography style={{ fontWeight: "600" }}>Brands</Typography>
          <ul className={styles.categoryBox}>
            {dataForDisplay?.map((item) => {
              if (brand?._id === item._id) {
                return (
                  <li
                    className={styles.categorylinkhigh}
                    key={item._id}
                    onClick={() => setBrand(item)}
                  >
                    {item.nameEN}
                  </li>
                );
              } else {
                return (
                  <li
                    className={styles.categorylink}
                    key={item._id}
                    onClick={() => setBrand(item)}
                  >
                    {item.nameEN}
                  </li>
                );
              }
            })}
            <button type="button" onClick={() => setExpanded(!expanded)}>
              {expanded ? "Show Less" : "Show More"}
            </button>
          </ul>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={customStyles}
      >
        <CloseIcon onClick={() => setModal(false)} />
        <div className={styles.filterBox}>
          <Typography style={{ fontWeight: "600" }}>Price</Typography>
          <Slider
            value={price}
            onChange={priceHandler}
            onChangeCommitted={priceCallback}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            min={0}
            max={2500}
          />
          <Typography style={{ fontWeight: "600" }}>Sizes</Typography>
          <ul className={styles.categoryBox}>
            {sizes?.map((item) => {
              if (size?._id === item._id) {
                return (
                  <li
                    className={styles.categorylinkhigh}
                    key={item._id}
                    onClick={() => setSize(item)}
                  >
                    {item.name}
                  </li>
                );
              } else {
                return (
                  <li
                    className={styles.categorylink}
                    key={item._id}
                    onClick={() => setSize(item)}
                  >
                    {item.name}
                  </li>
                );
              }
            })}
          </ul>
          <Typography style={{ fontWeight: "600" }}>Colors</Typography>
          <ul className={styles.categoryBox}>
            {colors?.map((item) => {
              if (color?._id === item._id) {
                return (
                  <li
                    className={styles.categorylinkhigh}
                    key={item._id}
                    onClick={() => setColor(item)}
                  >
                    {item.name}
                  </li>
                );
              } else {
                return (
                  <li
                    className={styles.categorylink}
                    key={item._id}
                    onClick={() => setColor(item)}
                  >
                    {item.name}
                  </li>
                );
              }
            })}
          </ul>
          <Typography style={{ fontWeight: "600" }}>Brands</Typography>
          <ul className={styles.categoryBox}>
            {dataForDisplay?.map((item) => {
              if (brand?._id === item._id) {
                return (
                  <li
                    className={styles.categorylinkhigh}
                    key={item._id}
                    onClick={() => setBrand(item)}
                  >
                    {item.nameEN}
                  </li>
                );
              } else {
                return (
                  <li
                    className={styles.categorylink}
                    key={item._id}
                    onClick={() => setBrand(item)}
                  >
                    {item.nameEN}
                  </li>
                );
              }
            })}
            <button type="button" onClick={() => setExpanded(!expanded)}>
              {expanded ? "Show Less" : "Show More"}
            </button>
          </ul>
        </div>
      </Modal>
      {loading ? (
        <div
          style={{
            display: "flex",
            margin: "300px auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SyncLoader size={30} color="rgba(254, 80, 0, 1)" />
        </div>
      ) : (
        <div className={styles.productswrapper}>
          {!mobileScreen && (
            <Typography
              style={{
                fontWeight: "700",
                textAlign: "center",
                fontSize: "20px",
                width: " 60%",
                borderRadius: "20px",
                margin: "0 auto",
                marginTop: "80px",
                marginBottom: "40px",
              }}
              onClick={() => setModal2(true)}
            >
              <FilterListIcon /> Filters
            </Typography>
          )}
          {mobileScreen && (
            <Typography
              style={{
                fontWeight: "700",
                textAlign: "center",
                fontSize: "20px",
                width: " 60%",
                borderRadius: "20px",
                margin: "0 auto",
                marginTop: "80px",
                marginBottom: "40px",
              }}
              onClick={() => setModal(true)}
            >
              <FilterListIcon /> Filters
            </Typography>
          )}
          {products?.length < 1 && (
            <h2 style={{ textAlign: "center", marginTop: "150px" }}>
              No Products found
            </h2>
          )}
          <ul className="card-ul">
            {products?.map((item) => {
              return <Card product={item} />;
            })}
          </ul>
          <div className="paginationBox">
            <Pagination
              count={pageSize}
              page={currentPage}
              siblingCount={1}
              onChange={(e, value) => setCurrentPage(value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryScreenProducts;
