import React, { useEffect, useState } from "react";
import Header from "../Header/Header";

import Footer from "./../Footer/Footer";

import HeaderArabic from "../Header/HeaderArabic";
import FooterArabic from "../Footer/FooterArabic";
import MyOrdersArabic from "./MyOrdersArabic";
import MyOrdersEnglish from "./MyOrdersEnglish";

const MyOrders = () => {
  const [lang, setLang] = useState("");
  useEffect(() => {
    setLang(sessionStorage.getItem("language"));
  }, []);
  return (
    <div>
      {" "}
      {lang === "ar" ? (
        <>
          <HeaderArabic />
          <MyOrdersArabic />
          <FooterArabic />
        </>
      ) : (
        <>
          <Header />
          <MyOrdersEnglish />
          <Footer />
        </>
      )}
    </div>
  );
};

export default MyOrders;
