import React, { useEffect, useState } from "react";
import Header from "../Header/Header";

import Footer from "./../Footer/Footer";

import HeaderArabic from "../Header/HeaderArabic";
import FooterArabic from "../Footer/FooterArabic";

import EditProfileArabic from "./EditProfileArabic";
import EditProfileEnglish from "./EditProfileEnglish";

const ProductDetail = () => {
  const [lang, setLang] = useState("");
  useEffect(() => {
    setLang(sessionStorage.getItem("language"));
  }, []);
  return (
    <div>
      {lang === "ar" ? (
        <>
          <HeaderArabic />
          <EditProfileArabic />
        </>
      ) : (
        <>
          <Header />
          <EditProfileEnglish />
          <Footer />
        </>
      )}
    </div>
  );
};

export default ProductDetail;
