import React, { useState, useEffect } from "react";
import "./ProductDetail.css";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import Carousel from "react-material-ui-carousel";
import { useDispatch } from "react-redux";
import { add } from "../../redux/cartSlice";
import { Add, Remove } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "./../../auth/useAuth";
import Rating from "@mui/material/Rating";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "./../../api/client";
import ReviewCard from "./ReviewCard";
import SyncLoader from "react-spinners/SyncLoader";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { mobile } from "./../../responsive";
import zIndex from "@mui/material/styles/zIndex";

const ProductDetailEnglish = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [products, setProducts] = useState();
  const [uploadVisible, setUploadVisible] = useState(false);
  const [data, setData] = useState();
  const [size, setSize] = useState("");
  const [sizes, setSizes] = useState();

  //colors
  const [color, setColor] = useState("");
  const [colors, setColors] = useState();

  const { user } = useAuth();

  //product review comment
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    fetchProducts();
  }, []);

  let { id } = useParams();

  const groupId = product?.groupId;

  const fetchProducts = async () => {
    setUploadVisible(true);
    if (groupId) {
      const { data } = await apiClient.get("/products/get-productby-groupid", {
        groupId,
      });
      setProducts(data);
      setData(product);
      let siz1;
      let col1;
      const col = await apiClient.get("/variations/getcolor");
      const siz = await apiClient.get("/variations/getsize");
      if (product?.color?._id) {
        col1 = product?.color;
        setColor(col1?.nameEN);
      } else {
        col1 = col?.data?.filter(function (el) {
          return el._id === product?.color;
        });
        setColor(col1[0]?.nameEN);
      }
      if(product?.size?._id){
        siz1 = product?.size;
        setSize(siz1?.nameEN);
      } else {
        siz1 = siz?.data?.filter(function (el) {
          return el._id === product?.size;
        });
        setSize(siz1[0]?.nameEN);
      }


       

      const uniqueArray = (a) =>
        [...new Set(a.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));

      var allColors = data?.map((item) => {
        return { label: item?.color?.nameEN, value: item?.color?.hexcode };
      });

      allColors = uniqueArray(allColors);
      // allColors = [...new Set(allColors)];
      setColors(allColors);
      

      var allSizes = data?.map((item) => {
        return item?.size?.nameEN;
      });
      allSizes = [...new Set(allSizes)];
      setSizes(allSizes);

    
      setUploadVisible(false);
    } else {
      const { data } = await apiClient.get("/products/get-productby-groupid", {
        groupId: id,
      });
      setProducts(data);
      setData(data[0]);

      const uniqueArray = (a) =>
        [...new Set(a.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));

      var AllColors = data?.map((item) => {
        return { label: item?.color?.nameEN, value: item?.color?.hexcode };
      });

      AllColors = uniqueArray(AllColors);
      setColors(AllColors);
      setColor(data[0]?.color?.nameEN);

      var allSizes = data?.map((item) => {
        return item?.size?.nameEN;
      });
      allSizes = [...new Set(allSizes)];
      setSizes(allSizes);
      setSize(data[0]?.size?.nameEN);

      setUploadVisible(false);
    }
  };

  const handleSubmit = () => {
    dispatch(add({ product: data, quantity }));
    navigate("/cart");
  };

  const submitReviewToggle = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const handleQuantity = (type) => {
    if (type === "dec") {
      quantity > 1 && setQuantity(quantity - 1);
    } else {
      if (product.countInStock > quantity) setQuantity(quantity + 1);
    }
  };

  const new_price =
    data?.sell_price - (data?.discount / 100) * data?.sell_price;

  const reviewSubmitHandler = async () => {
    const result = await apiClient.post("/products/create-review", {
      productId: data?._id,
      rating,
      comment,
      user,
    });
    if (result.ok) {
      swal("Review Submitted");
    }
    setOpen(false);
    window.location.reload();
  };

  const handleChangeSize = (e) => {
    setSize(e.target.value);
    const xyz = e.target.value;

    const product1 = products?.filter(function (el) {
      return el.size?.nameEN === xyz && el.color?.nameEN === color;
    });

    if (product1?.length > 0) {
      setData(product1[0]);
    } else {
      setData(null);
    }
  };

  const handleChangeColors = (el) => {
    setColor(el);

    const xyz = el;

    const product1 = products?.filter(function (el) {
      return el.color?.nameEN === xyz && el.size?.nameEN === size;
    });

    if (product1?.length > 0) {
      setData(product1[0]);
    } else {
      setData(null);
    }
  };

  return (
    <div>
      {uploadVisible ? (
        <div
          style={{
            display: "flex",
            margin: "300px auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SyncLoader size={30} color="rgba(254, 80, 0, 1)" />
        </div>
      ) : (
        <div className="productdetail-main">
          <div className="wrapper1">
            <div className="detail-main">
              <div className="detail-main-wrapper">
                <div className="product-title">
                  {data ? <h1>{data?.nameEN}</h1> : <h1>{color} with size - {size} Not Available. Please select different combination!!</h1>}
                </div>
                <div className="product-sku">
                  <p>
                    {data?.color?.nameEN}| {data?.size?.nameEN}
                  </p>
                  <p>{data?.brand?.nameEN}</p>
                </div>
                <div className="productImageMobileabc">
                  <Carousel>
                    {data?.image &&
                      data?.image.map((item, i) => (
                        <img
                          className="CarouselImageMobile"
                          key={i}
                          src={item}
                          alt={`${i} Slide`}
                        />
                      ))}
                  </Carousel>
                </div>
                <div className="product-price">
                  {data?.discount <= 0 && (
                    <p>
                      {data?.sell_price} <span>QAR</span>
                    </p>
                  )}
                  {data?.discount > 0 && (
                    <p>
                      {new_price} <span>QAR</span>
                    </p>
                  )}
                  {data?.discount > 0 && (
                    <p className="old-price">{data?.sell_price} QAR</p>
                  )}
                </div>
                <div className="product-amount-container">
                  <Remove onClick={() => handleQuantity("dec")} />
                  <span>{quantity}</span>
                  <Add onClick={() => handleQuantity("inc")} />
                </div>
                {colors?.length > 0 && colors[0] !== undefined && (
                  <div>
                    <p>Color: {data?.color?.nameEN}</p>

                    <Filter>
                      {colors?.map((item) => (
                        <FilterColor
                          value={item?.label}
                          color={item?.value}
                          key={item?.label}
                          onClick={() => handleChangeColors(item?.label)}
                        />
                      ))}
                    </Filter>
                  </div>
                )}

                {sizes?.length > 0 && sizes[0] !== undefined  && (
                  <FilterContainer>
                    <FilterTitle>Size</FilterTitle>
                    <>
                      <FilterSize onChange={handleChangeSize}>
                        {sizes?.map((item) => {
                          return (
                            <FilterSizeOption value={item} key={item}>
                              {item}
                            </FilterSizeOption>
                          );
                        })}
                      </FilterSize>
                    </>
                  </FilterContainer>
                )}
                <div className="product-btns">
                  {data?.countInStock > 0 ? (
                    <button
                      className="addtocart"
                      onClick={() => handleSubmit()}
                    >
                      <LocalMallOutlinedIcon className="svg" />
                      <span>Add to cart</span>
                    </button>
                  ) : (
                    <button className="addtocart">
                      <LocalMallOutlinedIcon className="svg" />
                      <span>Out Of Stock</span>
                    </button>
                  )}
                </div>
                <div className="product-options">
                  <div className="payment">
                    <strong>Payment</strong>
                    <p>
                      Pay Online or On Delivery, Safe and Secure using any visa
                      or master credit or debit card.
                    </p>
                    <div className="icons">
                      <p>Visa</p>
                      <p>Mastercard</p>
                    </div>
                  </div>
                  <div className="payment">
                    <strong>Shipping</strong>
                    <p>
                      Free Fast Shipping with best couriers in the shortest time
                      possible.
                    </p>
                    <div className="icons">
                      <p>Air</p>
                      <p>Ups</p>
                    </div>
                  </div>
                  <div className="payment">
                    <strong>Support</strong>
                    <p>
                      We're just one click away, while you are choosing or using
                      your products.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="image-main">
              <Carousel>
                {data?.image &&
                  data?.image.map((item, i) => (
                    <img
                      className="CarouselImageDesktop"
                      key={i}
                      src={item}
                      alt={`${i} Slide`}
                    />
                  ))}
              </Carousel>
            </div>
          </div>

          <div className="productData">
            {data?.video && (
              <div className="productVideoWrapper">
                <div class="ProductVideoTitle">Product Video</div>
                <ReactPlayer url={data?.video} width="100%" height="100%" />
              </div>
            )}

            <div className="productDescription">
              <div className="productDescriptionTitle">Description</div>
              <div className="productDescriptionContent">
                <p>{data?.descriptionEN}</p>
              </div>
            </div>
            <div className="productSpecs">
              <div class="ProductSpecsTitle">Features</div>
              <div class="ProductSpecsContent">
                <ul>
                  {data?.detailsEN.map((item) => {
                    return (
                      <li>
                        <p>
                          <span>{item.name} </span>
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="ProductReviews">
              <div className="ProductReviewsTitle">Reviews</div>
              <div className="ProductReviewsContent">
                {!user ? (
                  <div className="login-register">
                    <p>
                      You must be <a href="/login">logged in</a> to submit a
                      review.
                    </p>
                  </div>
                ) : (
                  <div className="login-register">
                    <p>
                      <a onClick={submitReviewToggle}>Submit Review</a>
                    </p>
                  </div>
                )}
                <Dialog
                  aria-labelledby="simple-dialog-title"
                  open={open}
                  onClose={submitReviewToggle}
                >
                  <DialogTitle>Submit Review</DialogTitle>
                  <DialogContent className="submitDialog">
                    <Rating
                      onChange={(e) => setRating(e.target.value)}
                      value={rating}
                      size="large"
                    />

                    <textarea
                      className="submitDialogTextArea"
                      cols="30"
                      rows="5"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={submitReviewToggle} color="secondary">
                      Cancel
                    </Button>
                    <Button color="primary" onClick={reviewSubmitHandler}>
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
                <div className="ReviewsList">
                  {data?.reviews && data?.reviews[0] ? (
                    <div className="reviews">
                      {data?.reviews &&
                        data?.reviews.map((review) => (
                          <ReviewCard key={review._id} review={review} />
                        ))}
                    </div>
                  ) : (
                    <p className="noReviews">No Reviews Yet</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const FilterColor = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid black;
  background-color: ${(props) => props.color};
  margin: 0px 5px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const FilterContainer = styled.div``;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const FilterTitle = styled.span`
  font-size: 20px;
  font-weight: 200;
`;

const FilterSize = styled.select`
  padding: 5px;
  margin-left: 10px;
`;

const FilterSizeOption = styled.option``;

export default ProductDetailEnglish;
