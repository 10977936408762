import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import "./SalePage.css";
import apiClient from "./../../api/client";
import Products from "../../components/Products/Products";
import { Pagination } from "@mui/material";
import SyncLoader from "react-spinners/SyncLoader";

function SalePageEnglish() {
  const [products, setProducts] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [loading, setLoading] = useState(true);

  const fetchProducts = async () => {
    const { data } = await apiClient.get("/products/sale-products", {
      pageNumber: currentPage,
    });
    setProducts(data?.products);
    setPageSize(data?.pageCount);
    setLoading(false);
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage]);

  return (
    <div>
      <Header />
      {loading ? (
        <div
          style={{
            display: "flex",
            margin: "300px auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SyncLoader size={30} color="rgba(254, 80, 0, 1)" />
        </div>
      ) : (
        <div className="saleWrapper">
          <h2 className="saleHeader">Sale Products</h2>
          <div className="divider" />
          <Products products={products} />
          <div className="paginationBox">
            <Pagination
              count={pageSize}
              page={currentPage}
              siblingCount={1}
              onChange={(e, value) => setCurrentPage(value)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SalePageEnglish;
