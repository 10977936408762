import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "./../Footer/Footer";
import HeaderArabic from "../Header/HeaderArabic";
import FooterArabic from "../Footer/FooterArabic";
import { useLocation } from "react-router-dom";
import SubCategoryScreenEnglish from "./SubCategoryScreenEnglish";
import SubCategoryScreenArabic from "./SubCategoryScreenArabic";

const SubCategoryScreen = () => {
  const [lang, setLang] = useState("");

  const { state } = useLocation();

  useEffect(() => {
    const abc = sessionStorage.getItem("language");
    setLang(abc);
  }, [lang]);

  return (
    <>
      {lang === "" ? (
        <h1>Loading..</h1>
      ) : (
        <div key={lang}>
          {lang === "ar" ? (
            <>
              <HeaderArabic />
              <SubCategoryScreenArabic state={state} />
              <FooterArabic />
            </>
          ) : (
            <>
              <Header />

              <SubCategoryScreenEnglish state={state} />
              <Footer />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SubCategoryScreen;
