import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import FooterArabic from "../../components/Footer/FooterArabic";
import Header from "../../components/Header/Header";
import HeaderArabic from "../../components/Header/HeaderArabic";
import BestSellersArabic from "./BestSellersArabic";
import BestSellersEnglish from "./BestSellersEnglish";

function BestSellers() {
  const [lang, setLang] = useState("");

  useEffect(() => {
    setLang(sessionStorage.getItem("language"));
  }, []);

  return (
    <div>
      <div>
        {lang === "ar" ? (
          <>
            <HeaderArabic />
            <BestSellersArabic />
            <FooterArabic />
          </>
        ) : (
          <>
            <Header />
            <BestSellersEnglish />
            <Footer />
          </>
        )}
      </div>
    </div>
  );
}

export default BestSellers;
