import React, { useEffect, useRef, useState } from "react";
import "./CategoryScreen.css";

import apiClient from "./../../api/client";
import CategoryScreenProductsArabic from "./CategoryScreenProductsArabic";

const CategoryArabicScreen = ({ state }) => {
  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling
  const [data, setData] = useState();
  const [SubCatId, setSubCatId] = useState();

  const scrl = useRef(0);

  const categoryId = state?._id;

  const fetchSubCategories = async () => {
    const { data } = await apiClient.get("/variations/get-sub-bycat", {
      catId: categoryId,
    });

    setData(data);
  };

  useEffect(() => {
    fetchSubCategories();
  }, [categoryId, SubCatId]);

  useEffect(() => {
    //Check width of the scollings
    if (
      scrl.current &&
      scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
    return () => {};
  }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift); // Updates the latest scrolled postion

    //For checking if the scroll has ended
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <div>
      <div className="CategoryScreenHeaderArabic">
        <section className="HeaderWrap">
          <h1 class="ArchiveHeaderTitle">{state?.nameAR}</h1>
        </section>
        <section className="ShadowText">{state?.nameAR}</section>
        {/* <Categories /> */}
        {/* <NavCategories /> */}
        <div className="CatNav">
          <div className="CatSlider">
            {scrollX !== 0 && (
              <button className="prev" onClick={() => slide(-120)}>
                <i className="fa fa-angle-left"></i>
              </button>
            )}
            <ul className="list" ref={scrl} onScroll={scrollCheck}>
              {data?.map((item) => {
                return (
                  <li>
                    <a onClick={() => setSubCatId(item._id)}>
                      <img src={item?.photo} />
                      <span>{item?.nameAR}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
            {!scrolEnd && (
              <button className="next" onClick={() => slide(+130)}>
                <i className="fa fa-angle-right"></i>
              </button>
            )}
          </div>
        </div>
        <CategoryScreenProductsArabic props={{ categoryId, SubCatId }} />
      </div>
    </div>
  );
};

export default CategoryArabicScreen;
