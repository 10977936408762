import React, { useState } from "react";
import "./EditProfile.css";
import FaceIcon from "@mui/icons-material/Face";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import swal from "sweetalert";
import useAuth from "./../../auth/useAuth";
import apiClient from "../../api/client";
import { useNavigate } from "react-router-dom";

const EditProfileEnglish = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const { user, logIn } = useAuth();

  const navigate = useNavigate();

  const updateProfileSubmit = async (e) => {
    e.preventDefault();
    const result = await apiClient.put("/users/profile", {
      id: user.id,
      name: name,
      email: email,
    });
    if (result.ok) {
      logIn(result.data.token);
      setName("");
      setEmail("");
      swal("Profile Updated", "Successfully", "success");
      navigate("/profile");
    } else {
      swal("Error!", "Occured Retry", "error");
    }
  };

  return (
    <>
      <div className="editprofile-container">
        <div className="box">
          <h2 className="updateProfileHeading">Update Profile</h2>

          <form
            className="updateProfileForm"
            encType="multipart/form-data"
            onSubmit={updateProfileSubmit}
          >
            <div className="updateProfileName">
              <FaceIcon />
              <input
                type="text"
                placeholder="Name"
                required
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="updateProfileEmail">
              <MailOutlineIcon />
              <input
                type="email"
                placeholder="Email"
                required
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <input type="submit" value="Update" className="updateProfileBtn" />
          </form>
        </div>
      </div>
    </>
  );
};

export default EditProfileEnglish;
