import { create } from "apisauce";

const apiClient = create({
  // baseURL: "http://localhost:5000/api",

  baseURL: "https://wasel.hkmheavyequipments.qa/api",
  headers: { Accept: "application/vnd.github.v3+json" },
});

export default apiClient;
