import React from "react";
import "./Terms.css";

const TermsArabic = () => {
  return (
    <div className="container-arabic">
      <h2 style={{ textAlign: "center", marginBottom: "30px" }}> الأحكام والشروط</h2>
      <p style={{ textAlign: "justify" }}>
        يدير واصل هذا الموقع. في جميع أنحاء الموقع ، تشير مصطلحات "نحن" و "نحن"
        و "خاصتنا" إلى واصل. يقدم واصل هذا الموقع ، بما في ذلك جميع المعلومات
        والأدوات والخدمات المتاحة من هذا الموقع إلى أنت ، المستخدم ، مشروطًا
        بقبولك لجميع الشروط والأحكام والسياسات والإشعارات المذكورة هنا. من خلال
        زيارة موقعنا و / أو شراء شيء منا ، فإنك تشارك في "خدمتنا" و توافق على
        الالتزام بالشروط والأحكام التالية ("شروط الخدمة" ، "الشروط") ، بما في
        ذلك تلك الشروط والأحكام والسياسات الإضافية المشار إليها هنا و / أو
        المتاحة عن طريق الارتباط التشعبي. تنطبق شروط الخدمة هذه على جميع مستخدمي
        الموقع ، بما في ذلك على سبيل المثال لا الحصر المستخدمين المتصفحات
        والموردين والعملاء والتجار و / أو المساهمين في المحتوى. يرجى قراءة هذه
        الشروط الخدمة بعناية قبل الوصول إلى موقعنا الإلكتروني أو استخدامه. من
        خلال الوصول إلى أو استخدام أي جزء من الموقع ، أنت توافق على الالتزام
        بشروط الخدمة هذه. إذا كنت لا توافق على جميع الشروط والأحكام من هذه
        الاتفاقية ، فلا يجوز لك الوصول إلى موقع الويب أو استخدام أي خدمات. إذا
        كانت هذه الشروط تعتبر الخدمة عرضًا ، ويقتصر القبول صراحةً على شروط
        الخدمة هذه. أي جديد تخضع الميزات أو الأدوات المضافة إلى المتجر الحالي
        أيضًا لشروط الخدمة. أنت تستطيع مراجعة أحدث إصدار من شروط الخدمة في أي
        وقت على هذه الصفحة. نحن نحتفظ ب الحق في تحديث أو تغيير أو استبدال أي جزء
        من شروط الخدمة هذه عن طريق نشر التحديثات و التغييرات على موقعنا. تقع على
        عاتقك مسؤولية مراجعة هذه الصفحة بشكل دوري لمعرفة التغييرات. لك استمرار
        استخدام أو الوصول إلى موقع الويب بعد نشر أي تغييرات يشكل قبول هذه
        التغييرات
      </p>
    </div>
  );
};

export default TermsArabic;
