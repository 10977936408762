import React from "react";
import "./Footer.css";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import tiktok from "../../assets/tiktok.png"

const FooterArabic = () => {
  return (
    <div className="footermain">
      <div className="footer-arabic">
        <ul>
          <p>وصل</p>
          <li>
            <a>تسجيل الدخول</a>
          </li>
          <li>
            <a>طلباتي</a>
          </li>
          <li>
            <a>اشتراك</a>
          </li>
        </ul>
        <ul>
          <p>المجموعات</p>
          <li>
            <a href="/brand/6">Xiomi</a>
          </li>
          <li>
            <a href="/brand/7">Anker</a>
          </li>

          <li>
            <a href="/brand/4">Green Lion</a>
          </li>
          <li>
            <a href="/brand/20">Powerlogy</a>
          </li>
          <li>
            <a href="/brand/5">Porodo</a>
          </li>
          <li>
            <a href="/brand/3">Baseus</a>
          </li>
        </ul>
        <ul>
          <p>شركة</p>
          <li>
            <a href="/aboutus">معلومات عنا</a>
          </li>
          <li>
            <a href="/contactus"> اتصل بنا</a>
          </li>
          <li>
            <a href="/privacypolicy">سياسة الخصوصية</a>
          </li>
          <li>
            <a href="/termsandcondition">الأحكام والشروط</a>
          </li>
          <li>
            <a href="/returnpolicy">سياسة العائدات</a>
          </li>
        </ul>
        <div className="social-media">
        <a style={{ paddingLeft: "12px"}}  href="https://www.facebook.com/wasel.qa"><FacebookOutlinedIcon/></a>
      <a style={{ paddingLeft: "12px"}} href="https://www.instagram.com/wasel.qa/?igshid=YmMyMTA2M2Y%3D"><InstagramIcon/></a>
      <a href="https://wa.me/+97450301166" target="_blank">
          <WhatsAppIcon />
        </a>
      <a className="tiktok-ar" href="https://vm.tiktok.com/ZS8XRaRpy/"><img alt="tiktok" src={tiktok} height={20}/></a>
    

        </div>
      </div>
      <div className="copyright-arabic">
        <p className="copyright-1">
          {""} حقوق النشر © 2022
          <span style={{ fontWeight: 600, marginRight: "5px" }}> واصل</span>.
          جميع الحقوق محجوز
        </p>
        {/* <p>
          Developed by{" "}
          <a href="https://mmhtechnologies.com/">MMH Technologies</a>
        </p> */}
      </div>
    </div>
  );
};

export default FooterArabic;
