import React, { Fragment } from "react";

import { Link } from "react-router-dom";

import "./Profile.css";
import useAuth from "./../../auth/useAuth";

const ProfileArabic = () => {
  const { user } = useAuth();

  return (
    <Fragment>
      <div className="profileContainerArabic">
        <div>
          <h1>My Profile</h1>
          <img
            src={
              "https://www.nicepng.com/png/detail/202-2024580_png-file-profile-icon-vector-png.png"
            }
            alt="img"
          />
          <Link to="/edit-profile">Edit Profile</Link>
        </div>
        <div>
          <div>
            <h4>Full Name</h4>
            <p>{user?.name}</p>
          </div>
          <div>
            <h4>Email</h4>
            <p>{user?.email}</p>
          </div>

          <div>
            <Link to="/myorders">My Orders</Link>
            <Link to="/update-password">Change Password</Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfileArabic;
