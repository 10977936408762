import React, { useEffect, useState } from "react";
import Header from "../Header/Header";

import Footer from "./../Footer/Footer";

import HeaderArabic from "../Header/HeaderArabic";
import FooterArabic from "../Footer/FooterArabic";
import CategoryArabicScreen from "./CategoryArabicScreen";
import CategoryEnglishScreen from "./CategoryEnglishScreen";
import { useLocation } from "react-router-dom";

const CategoryScreen = () => {
  const [lang, setLang] = useState("");

  const { state } = useLocation();

  useEffect(() => {
    const abc = sessionStorage.getItem("language");
    setLang(abc);
  }, [lang]);

  return (
    <>
      {lang === "" ? (
        <h1>Loading..</h1>
      ) : (
        <div key={lang}>
          {lang === "ar" ? (
            <>
              <HeaderArabic />
              <CategoryArabicScreen state={state} />
              <FooterArabic />
            </>
          ) : (
            <>
              <Header />
              <CategoryEnglishScreen state={state} />
              <Footer />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CategoryScreen;
