import React from "react";
import "./Card.css";
import { useNavigate } from "react-router-dom";

const Card = ({ product }) => {
  const navigate = useNavigate();
  const discount = (product?.discount / 100) * product.sell_price;
  const newprice = Number(product.sell_price - discount).toFixed(2);
  return (
    <div
      className="card-container"
      onClick={() =>
        navigate(`/product/${product?.groupId}`, { state: product })
      }
    >
      {discount > 0 && (
        <div className="discountWrapper">
          <p>{product?.discount}% </p>
        </div>
      )}
      <a>
        <div className="img-wrapper">
          <img src={product?.image?.length > 0  ? product?.image[0] : ""} alt="prd-img" />
        </div>
        <div className="info-container">
          <p className="card-category">{product?.category?.nameEN}</p>
          <p className="card-name">{product?.nameEN}</p>
          {discount > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <p className="card-price">{newprice} QAR</p>
              <p
                style={{
                  textDecoration: "line-through",
                  color: "grey",
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                {product?.sell_price} QAR
              </p>
            </div>
          ) : (
            <p className="card-price">{product?.sell_price} QAR</p>
          )}
        </div>
      </a>
    </div>
  );
};

export default Card;
