import React from "react";
import "./AboutUs.css";

const AboutUsEnglish = () => {
  return (
    <div className="container">
      <h2 style={{ textAlign: "center", marginBottom: "30px" }}>About Us</h2>
      <p style={{ textAlign: "justify" }}>
        Wasel store is a differentiated last-mile e-commerce solution that
        provides a platform for Electronic, Mobile Accessories, and Smart
        devices, offering e-commerce, connection to brands, payments as
        microfinance, and last-mile fulfillment to the customers. It is the
        ultimate online shopping destination for Qatar offering a completely
        hassle-free shopping experience through secure and trusted gateways. We
        offer you trendy and reliable shopping with all your favorite brands and
        more. Now shopping is easier, faster and always joyous. We help you make
        the right choice here. Wasel Store is the most customer-friendly online
        small business to sell a wide selection of authentic products to
        customers without having trouble. Wasel Store is aggregating small
        businesses into a network, enabling customers to buy their essential
        products from our website. Wasel Store has been launched in March 2022.
        Wasel store showcases products from all categories such as Electronic,
        Mobile Accessories, and still counting! Our collection combines the
        latest in fashion trends as well as all-time favorites. Our products are
        exclusively selected for you. We, at Wasel Store, have all that you need
        under one umbrella. We provide our customers with memorable online
        shopping experiences. Our dedicated Wasel store quality assurance team
        works round the clock to personally make sure the right packages reach
        on time. You can choose whatever you like. We deliver it right to your
        address across QATAR. Our services are at your doorstep all the time.
        Get the best products with the best online shopping experience every
        time. You will enjoy online shopping here
      </p>
    </div>
  );
};

export default AboutUsEnglish;
