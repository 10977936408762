import React from "react";
import "./Return.css";

const ReturnEnglish = () => {
  return (
    <div className="container">
      <h2 style={{ textAlign: "center", marginBottom: "30px" }}>About Us</h2>
      <p style={{ textAlign: "justify" }}>
        We have a 14-days return policy, which means you have 30 days after
        receiving your item to request a return. To be eligible for a return,
        your item must be in the same condition that you received it, unworn or
        unused, with tags, and in its original packaging. You’ll also need the
        receipt or proof of purchase. To start a return, you can contact us at
        info@wasel.store If your return is accepted, we’ll send you a return
        shipping label, as well as instructions on how and where to send your
        package. Items sent back to us without first requesting a return will
        not be accepted. You can always contact us for any return questions at
        info@wasel.store Damages and issues Please inspect your order upon
        reception and contact us immediately if the item is defective, or
        damaged or if you receive the wrong item so that we can evaluate the
        issue and make it right. Exceptions / non-returnable items Certain types
        of items cannot be returned, like perishable goods, custom products, and
        personal care goods. We also do not accept returns for hazardous
        materials, flammable liquids, or gases. Please get in touch if you have
        questions or concerns about your specific item. Unfortunately, we cannot
        accept returns on sale items or gift cards. Exchanges The fastest way to
        ensure you get what you want is to return the item you have, and once
        the return is accepted, make a separate purchase for the new item.
        Refunds We will notify you once we’ve received and inspected your
        return, and let you know if the refund was approved or not. If approved,
        you’ll be automatically refunded on your original payment method. Please
        remember it can take some time for your bank or credit card company to
        process and post the refund too.
      </p>
    </div>
  );
};

export default ReturnEnglish;
