import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "./../Footer/Footer";
import HeaderArabic from "../Header/HeaderArabic";
import FooterArabic from "../Footer/FooterArabic";
import CartArabic from "./CartArabic";
import CartEnglish from "./CartEnglish";
import { useSelector } from "react-redux";

const Cart = () => {
  const [lang, setLang] = useState("");
  useEffect(() => {
    setLang(sessionStorage.getItem("language"));
  }, []);

  const items = useSelector((state) => state.cart.cart);

  return (
    <div>
      {lang === "ar" ? (
        <>
          <HeaderArabic />
          <CartArabic cart={items} />
          <FooterArabic />
        </>
      ) : (
        <>
          <Header />
          <CartEnglish cart={items} />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Cart;
