import "./App.css";
import Home from "./screens/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductDetail from "./components/ProductDetails/ProductDetail";
import CategoryScreen from "./components/CategoryScreen/CategoryScreen";
import Profile from "./components/Profile/Profile";
import EditProfile from "./components/Profile/EditProfile";
import UpdatePassword from "./components/Profile/UpdatePassword";
import MyOrders from "./components/Order/MyOrders";
import Cart from "./components/Cart/Cart";
import Shipping from "./components/Shipping/Shipping";
import ConfirmOrder from "./components/Cart/ConfirmOrder";
import Login from "./screens/Login";
import Register from "./screens/Register";
import OrderDetail from "./components/Order/OrderDetail";
import { Provider, useSelector } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import AuthContext from "./auth/context";
import { useState } from "react";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import Payment from "./components/Payment/Payment";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import ScrollToTop from "./ScrollToTop";
import SalePage from "./screens/SalePage/SalePage";
import Brand from "./components/Brand/Brand";
import BestSellers from "./screens/BestSellers/BestSellers";

import Search from "./components/SearchProducts/Search";
import OrderSuccess from "./components/OrderSuccess/OrderSuccess";
import AboutUs from "./components/AboutUs/AboutUs";
import ContactUs from "./components/ContactUs/ContactUs";
import Terms from "./components/Terms/Terms";
import ReturnPolicy from "./components/ReturnPolicy/ReturnPolicy";
import NewArrivals from "./screens/NewArrivals/NewArrivals";
import SubCategoryScreen from "./components/SubcategoryScreenMobileMenu/SubCategoryScreen";

function App() {
  const [user, setUser] = useState();

  const restoreUser = async () => {
    const user = await sessionStorage.getItem("token");
    if (user) setUser(jwtDecode(user));
  };

  useEffect(() => {
    restoreUser();
  }, []);

  return (
    <div>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AuthContext.Provider value={{ user, setUser }}>
            <Router>
              <ScrollToTop>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/product/:id" element={<ProductDetail />} />
                  <Route path="/brand/:id" element={<Brand />} />
                  <Route path="/category" element={<CategoryScreen />} />
                  <Route path="/subcategory" element={<SubCategoryScreen />} />
                  <Route path="/sales" element={<SalePage />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/bestselling" element={<BestSellers />} />
                  <Route path="/newarrivals" element={<NewArrivals />} />
                  <Route
                    path="/profile"
                    element={user ? <Profile /> : <Login />}
                  />
                  <Route
                    path="/success"
                    element={user ? <OrderSuccess /> : <Login />}
                  />
                  <Route
                    path="/edit-profile"
                    element={user ? <EditProfile /> : <Login />}
                  />
                  <Route
                    path="/update-password"
                    element={user ? <UpdatePassword /> : <Login />}
                  />
                  <Route
                    path="/myorders"
                    element={user ? <MyOrders /> : <Login />}
                  />
                  <Route
                    path="/order-detail"
                    element={user ? <OrderDetail /> : <Login />}
                  />
                  <Route path="/cart" element={<Cart />} />
                  <Route
                    path="/shipping"
                    element={user ? <Shipping /> : <Login />}
                  />
                  <Route
                    path="/confirm-order"
                    element={user ? <ConfirmOrder /> : <Login />}
                  />

                  <Route
                    path="/payment"
                    element={user ? <Payment /> : <Login />}
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route path="/aboutus" element={<AboutUs />} />
                  <Route path="/contactus" element={<ContactUs />} />
                  <Route path="/termsandcondition" element={<Terms />} />
                  <Route path="/returnpolicy" element={<ReturnPolicy />} />
                </Routes>
              </ScrollToTop>
            </Router>
          </AuthContext.Provider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
