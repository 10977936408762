import React, { useEffect, useState } from "react";
import Header from "../Header/Header";

import Footer from "./../Footer/Footer";

import HeaderArabic from "../Header/HeaderArabic";
import FooterArabic from "../Footer/FooterArabic";
import OrderDetailsArabic from "./OrderDetailsArabic";
import OrderDetailEnglish from "./OrderDetailsEnglish";




const OrderDetail = () => {
    const [lang, setLang] = useState("");
    useEffect(() => {
      setLang(sessionStorage.getItem("language"));
    }, []);
  return (
    <div>  {lang === "ar" ? (
        <>
          <HeaderArabic />
          <OrderDetailsArabic />
          <FooterArabic />
        </>
      ) : (
        <>
          <Header />
          <OrderDetailEnglish  />
          <Footer />
        </>
      )}
    </div>
  );
};

export default OrderDetail;
