import React from "react";
import CardArabic from "../Card/CardArabic";
import styles from "./Products.module.css";

const ProductsArabic = ({ products }) => {
  return (
    <div className={styles.productscontainerArabic}>
      <div className={styles.productswrapper}>
        <ul>
          {products?.map((item) => {
            return <CardArabic product={item} />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProductsArabic;
