import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import "./Search.css";
import apiClient from "./../../api/client";
import Products from "../../components/Products/Products";
import { Pagination } from "@mui/material";
import SyncLoader from "react-spinners/SyncLoader";

function SearchProductsEnglish({ query }) {
  const [currentPage, setCurrentPage] = useState(1);

  const [products, setProducts] = useState([]);
  const [products2, setProducts2] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(true);

  const totalPages = Math.floor( Number(products?.length / pageSize));
  const lastIndex = pageSize * currentPage;
  const firstIndex = lastIndex - pageSize;

  const fetchProducts = async () => {
    
    const { data } = await apiClient.get("/products/search", {
      Query: query,
      pageNumber: currentPage,
    });

    setProducts(data);
    setProducts2(data.slice(firstIndex, lastIndex));
    setPageSize(20);
    setLoading(false);
  };

  useEffect(() => {
    setProducts([]);
    fetchProducts();
  }, [currentPage, query]);

  return (
    <div>
      <Header />
      {loading ? (
        <div
          style={{
            display: "flex",
            margin: "300px auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SyncLoader size={30} color="rgba(254, 80, 0, 1)" />
        </div>
      ) : (
        <div className="saleWrapper">
          <h2 className="saleHeader">Your Search for "{query}"</h2>
          <div className="divider" />
          <Products products={products2} />
          <div className="paginationBox">
            <Pagination
              count={totalPages}
              page={currentPage}
              siblingCount={1}
              onChange={(e, value) => {
                setCurrentPage(value);
                const element = document.getElementById("searchproducts");
                element.scrollIntoView();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchProductsEnglish;
