import React from "react";
import "./Return.css";

const ReturnArabic = () => {
  return (
    <div className="container-arabic">
      <h2 style={{ textAlign: "center", marginBottom: "30px" }}>
        {" "}
        سياسة العائدات
      </h2>
      <p style={{ textAlign: "justify" }}>
        لدينا سياسة إرجاع لمدة 14 يومًا ، مما يعني أن لديك 30 يومًا بعد استلام
        العنصر الخاص بك لطلبه عودة. لكي تكون مؤهلاً للحصول على عائد ، يجب أن
        يكون العنصر الخاص بك في نفس الحالة التي استلمته بها ، غير ملبوس أو غير
        مستعمل ، مع بطاقات وفي عبوته الأصلية. ستحتاج أيضًا إلى إيصال أو إثبات
        شراء. لبدء الإرجاع ، يمكنك الاتصال بنا على info@wasel.store إذا تم قبول
        إرجاعك ، فسنقوم بذلك نرسل لك ملصق شحن مرتجعات ، بالإضافة إلى إرشادات حول
        كيفية ومكان إرسال الطرد الخاص بك. لن يتم قبول العناصر المرسلة إلينا دون
        طلب الإرجاع أولاً. يمكنك دائما الاتصال إذا كانت لديك أي أسئلة متعلقة
        بالإرجاع على info@wasel.store ، فإن الأضرار والمشكلات يرجى فحص طلبك
        عليها الاستقبال والاتصال بنا على الفور إذا كان العنصر معيبًا أو تالفًا
        أو إذا تلقيت عنصر خاطئ حتى نتمكن من تقييم المشكلة وتصحيحها. استثناءات /
        سلع غير قابلة للإرجاع لا يمكن إرجاع أنواع معينة من العناصر ، مثل السلع
        القابلة للتلف والمنتجات المخصصة والأغراض الشخصية سلع العناية. كما أننا
        لا نقبل إرجاع المواد الخطرة أو السوائل القابلة للاشتعال أو الغازات. يرجى
        الاتصال إذا كانت لديك أسئلة أو مخاوف بشأن العنصر المحدد الخاص بك. للأسف
        نحن لا يمكن قبول إرجاع سلع البيع أو بطاقات الهدايا. التبادلات أسرع طريقة
        لضمان حصولك على ماذا الذي تريده هو إرجاع العنصر الذي لديك ، وبمجرد قبول
        الإرجاع ، قم بإجراء عملية شراء منفصلة للعنصر الجديد. المبالغ المستردة
        سنقوم بإعلامك بمجرد استلامنا وفحص عودتك ، والسماح بذلك أنت تعرف ما إذا
        تمت الموافقة على رد الأموال أم لا. إذا تمت الموافقة ، فسيتم رد الأموال
        إليك تلقائيًا على طريقة الدفع الأصلية. يرجى تذكر أن الأمر قد يستغرق بعض
        الوقت للبنك أو بطاقة الائتمان الخاصة بك الشركة لمعالجة وإرسال رد الأموال
        أيضًا
      </p>
    </div>
  );
};

export default ReturnArabic;
