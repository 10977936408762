import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicyArabic = () => {
  return (
    <div className="container-arabic">
      <h2>سياسة الخصوصية</h2>
      <p style={{ textAlign: "justify" }}>
        تعكس جميع العبوات نفس التصميم والجودة المميزين للعنصر الذي اخترته. فيما
        يتعلق بشحن البضائع للمستهلكين ، أينما كانوا ، ملكية ل يجب أن تنتقل
        البضائع إلى المشتري عند تسليم البضائع إلى الناقل المشترك. لا شيء في هذا
        الموقع يمنحك أي ترخيص أو حق لاستخدام أي علامة تجارية معروضة على هذا
        الموقع بدون إذن كتابي مسبق من مالك العلامة التجارية. استخدامك أو إساءة
        استخدام أي منها العلامات التجارية المعروضة على هذا الموقع أو أي محتوى
        آخر على هذا الموقع ممنوع منعا باتا. إذا كان التسعير حدث خطأ لم تكن هناك
        محاولة لإيذاء أي عميل بشكل غير قانوني. إذا حدث خطأ نحن سيحاول تصحيحه في
        أسرع وقت ممكن. وفقًا للقانون المعياري للإعلان عندما يكون الأمر كذلك طلب
        عبر الإنترنت ليس اتفاقًا ملزمًا وفقًا لنظام المحاكم. انها ليست في نيتنا
        يعلنون بتسعير غير صحيح. لسوء الحظ ، يمكن أن تحدث الأخطاء وتحدث بالفعل.
        حتى يتم الأمر شحنت عقد لم يتم إدخاله في بيانات حول نشاط زوار هذا الموقع
        يتم تقديمها تلقائيًا إلى واصل عند تسجيل الدخول. نحن نستخدمه للمراجعة
        الداخلية بشكل إجمالي و أشكال غير شخصية. عناوين البريد الإلكتروني وبيانات
        التعريف الشخصية الأخرى عنك معروفة لدى واصل فقط عندما يتم تقديمها طواعية
        أو إرسالها إلينا. بالإضافة إلى ذلك ، نستخدم في بعض الأحيان عناوين البريد
        الإلكتروني وغيرها من معلومات التعريف الشخصية للاتصال بالزوار الذين
        يتواصلون معنا ، نستخدم أيضًا عناوين الشحن لعملائنا لنرسل لهم كتالوجاتنا
        التي نوفرها تشعر أنها ستكون موضع اهتمام بالنسبة لهم.
      </p>
      <h3>استخدام المعلومات الشخصية</h3>
      <p style={{ textAlign: "justify" }}>
        نحن نستخدم معلوماتك الشخصية لتقديم خدماتنا لك ، والتي يتضمن: عرض
        المنتجات للبيع ومعالجة المدفوعات والشحن و تلبية طلبك ، وإبقائك على اطلاع
        دائم على المنتجات الجديدة ، الخدمات والعروض.
      </p>
    </div>
  );
};

export default PrivacyPolicyArabic;
