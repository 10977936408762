import React, { useEffect, useState } from "react";
import "./Header.css";
import logo from "../../assets/logo.png";
import Anker from "../../assets/Anker.png";
import Baseus from "../../assets/Baseus.png";
import Green_Lion from "../../assets/Green_Lion.png";
import MI from "../../assets/MI.png";
import Porodo from "../../assets/Porodo.png";
import Powerlogy from "../../assets/Powerlogy.png";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import Modal from "react-modal";
import apiClient from "./../../api/client";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import useAuth from "./../../auth/useAuth";
import styled from "styled-components";
import { mobile } from "./../../responsive";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import { LocationOn } from "@mui/icons-material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(140, 197, 227, 255)",
  },
  overlay: { zIndex: 1000 },
};

const Header = () => {
  const [lang, setLang] = useState();
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState();
  const [data, setData] = useState();
  const [subcategory, setSubcategory] = useState();
  const [mobileHeading, setMobileHeading] = useState();
  const [back, setback] = useState(false);
  const items = useSelector((state) => state.cart.cart);

  const navigate = useNavigate();
  const { user, logOut } = useAuth();

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variations/get-categories");
    setData(data);
  };
  const getAllSubCategories = async () => {
    const { data } = await apiClient.get("/variations/get-sub-categories");
    setSubcategory(data);
  };

  useEffect(() => {
    setMobileHeading("Shop");
    fetchCategories();
    getAllSubCategories();
  }, [back]);

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleChange = (lang) => {
    setLang(lang);
    sessionStorage.setItem("language", lang);
    window.location.reload();
  };

  useEffect(() => {
    setLang(sessionStorage.getItem("language"));
  }, []);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleMobileItem = (item) => {
    if (item.category) {
      navigate("/subcategory", { state: item });
      setModal(false);
    } else {
      const newarray = subcategory.filter((subcat) => {
        return item._id === subcat.category._id;
      });

      if (newarray.length > 0) {
        setMobileHeading(item.nameEN);
        setData(newarray);
      } else {
        navigate("/category", { state: item });
        setModal(false);
      }
    }
  };

  const handleSearch = async (e) => {
    setIsOpen(false);
    navigate("/search", { state: { search } });
    setSearch("");
  };

  return (
    <div className="header-container">
      {/* mobile */}

      <section className="header-wrapper">
        <div class="mobile-nav">
          <div className="open-menu" onClick={() => handleModal()}>
            {modal ? <></> : <i class="fa fa-bars" />}
          </div>
        </div>
        {modal && (
          <div class="mobile-wrapper">
            <div onClick={() => handleModal()} className="close-menu">
              <i class="fa fa-times" style={{ background: "transparent" }} />
            </div>

            <div class="mobile-search-wrapper">
              <input
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <SearchOutlined
                style={{
                  color: "grey",
                  fontSize: 30,
                  background: "transparent",
                }}
                onClick={handleSearch}
              />
            </div>
            <div className="mobile-language-wrapper">
              <a href="https://goo.gl/maps/5FiMu5g24Xdjs68k7" target="_blank">
                <LocationOn style={{ color: "black" }} />
              </a>
              <p style={{ margin: "0 10px" }}>|</p>
              <a href="https://wa.me/+97450301166" target="_blank">
                <WhatsAppIcon />
              </a>
              <p style={{ margin: "0 10px" }}>|</p>
              <a onClick={() => handleChange("eng")}>
                <p>Eng</p>
              </a>
              <p style={{ margin: "0 10px" }}>|</p>
              <a onClick={() => handleChange("ar")}>
                <p>Ar</p>
              </a>
            </div>
            <div className="mobile-brands-wrapper">
              <ul>
                {mobileHeading === "Shop" ? (
                  <li>
                    <a>{mobileHeading} 🛒</a>
                  </li>
                ) : (
                  <li onClick={() => setback(!back)}>
                    <a>
                      <KeyboardArrowLeftIcon />
                      {mobileHeading}
                    </a>
                  </li>
                )}
                {data?.map((item) => {
                  return (
                    <li
                      key={item._id}
                      // onClick={() => {
                      //   navigate("/category", { state: item });
                      //   setModal(false);
                      // }}
                      onClick={() => handleMobileItem(item)}
                    >
                      <a>{item.nameEN}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        {!modal && (
          <div className="menu-col  left">
            <a href="/">
              <img alt="header-logo" src={logo} />
            </a>
          </div>
        )}

        <div className="menu-col center">
          <ul>
            <li>
              <a href="/brand/7">
                <img alt="Anker" src={Anker} />
              </a>
            </li>
            <li>
              <a href="/brand/3">
                <img alt="Baseus" src={Baseus} />
              </a>
            </li>

            <li>
              <a href="/brand/4">
                <img alt="Green_Lion" src={Green_Lion} />
              </a>
            </li>
            <li>
              <a href="/brand/6">
                <img alt="MI" src={MI} />
              </a>
            </li>
            <li>
              <a href="/brand/5">
                <img alt="Porodo" src={Porodo} />
              </a>
            </li>
            <li>
              <a href="/brand/20">
                <img alt="Powerlogy" src={Powerlogy} />{" "}
              </a>
            </li>
          </ul>
        </div>
        {!modal && (
          <div className="menu-col right">
            <a className="search-icon" onClick={openModal}>
              <SearchOutlinedIcon className="svg" />
            </a>
            {!user && (
              <a href="/profile">
                <AccountCircleOutlinedIcon className="svg" />
              </a>
            )}
            {user && (
              <a href="/profile">
                <AccountCircleOutlinedIcon className="svg" />
              </a>
            )}

            <a href="/cart">
              <Badge color="primary" badgeContent={items?.length}>
                <LocalMallOutlinedIcon className="svg" />
              </Badge>
            </a>
          </div>
        )}
      </section>

      <div className="menu-col translate">
        <a href="https://goo.gl/maps/5FiMu5g24Xdjs68k7" target="_blank">
          <LocationOn style={{ color: "black" }} />
        </a>

        <a href="https://wa.me/+97450301166" target="_blank">
          <WhatsAppIcon />
        </a>
        <a style={{ cursor: "pointer" }} onClick={() => handleChange("eng")}>
          <p>Eng</p>
        </a>
        <p>|</p>
        <a onClick={() => handleChange("ar")}>
          <p>Ar</p>
        </a>
        {user && (
          <a onClick={() => logOut()}>
            <LogoutIcon />
          </a>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ position: "relative" }}>
          <div
            onClick={() => closeModal()}
            style={{
              display: "flex",
              position: "absolute",
              top: "-450%",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <i
              class="fa fa-times-circle-o "
              style={{
                background: "transparent",
                fontSize: "40px",
                color: "white",
              }}
            />
            <p
              style={{ marginTop: "12px", color: "white", marginLeft: "10px" }}
            >
              Close
            </p>
          </div>
          <div
            style={{
              width: "70%",
              margin: "0 auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              marginTop: "40vh",
              position: "relative",
            }}
          >
            <input
              placeholder="Search Product here..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{
                width: "50%",
                padding: "20px 20px",
                borderRadius: "20px",
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <SearchOutlined
              style={{
                color: "grey",
                fontSize: 40,
                background: "transparent",
              }}
              onClick={handleSearch}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const MenuItem2 = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;

export default Header;
