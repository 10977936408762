import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/client";
import CardArabic from "../Card/CardArabic";

const SaleArabic = () => {
  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling

  const scrl = useRef(0);

  const [data, setData] = useState();

  const navigate = useNavigate();

  const fetchData = async () => {
    const { data } = await apiClient.get("/products/sale-products");

    setData(data.products);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    //Check width of the scollings
    if (
      scrl.current &&
      scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
    return () => {};
  }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift); // Updates the latest scrolled postion

    //For checking if the scroll has ended
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  return (
    <div className="best-container-arabic">
      <div className="header-arabic">
        <div className="viewAllWrapperArabic">
          <a href="/sales">مشاهدة الكل</a>
        </div>
        <strong>للبيع</strong>
      </div>

      <div className="slider-wrapper">
        {scrollX !== 0 && (
          <button className="prev" onClick={() => slide(+300)}>
            <i className="fa fa-angle-left"></i>
          </button>
        )}
        <div className="slider-list-arabic" ref={scrl} onScroll={scrollCheck}>
          {data?.map((item) => {
            return <CardArabic product={item} />;
          })}
        </div>
        {!scrolEnd && (
          <button className="next" onClick={() => slide(-300)}>
            <i className="fa fa-angle-right"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default SaleArabic;
