import React, { useEffect, useState } from "react";
import BestSelling from "../components/BestSelling/BestSelling";
import BestSellingArabic from "../components/BestSelling/BestSellingArabic";
import Categories from "../components/Category/Categories";
import CategoriesArabic from "../components/Category/CategoriesArabic";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import HeaderArabic from "../components/Header/HeaderArabic";
import HomeBanners from "../components/HomeBanners/HomeBanners";
import HomeBannersArabic from "../components/HomeBanners/HomeBannersArabic";
import NewArrivals from "../components/NewArrivals/NewArrivals";
import NewArrivalsArabic from "../components/NewArrivals/NewArrivalsArabic";
import Slider from "../components/Slider/Slider";
import Sale from "../components/Sale/Sale";
import SaleArabic from "../components/Sale/SaleArabic";
import FooterArabic from "../components/Footer/FooterArabic";
import BrandsList from "../components/BrandCard/BrandsList";

const Home = () => {
  const [lang, setLang] = useState("");
  useEffect(() => {
    setLang(sessionStorage.getItem("language"));
  }, []);

  return (
    <>
      {lang === "ar" ? (
        <>
          <HeaderArabic />
          <Slider />
          <CategoriesArabic />
          <HomeBannersArabic />
          {/* <BrandsList /> */}
          <BestSellingArabic />
          <NewArrivalsArabic />
          <SaleArabic />
          <FooterArabic />
        </>
      ) : (
        <>
          <Header />
          <Slider />
          <Categories />
          <HomeBanners />
          {/* <BrandsList /> */}
          <BestSelling />
          <NewArrivals />
          <Sale />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
