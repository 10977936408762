import React from "react";
import "./AboutUs.css";

const AboutUsArabic = () => {
  return (
    <div className="container-arabic">
      <h2 style={{ textAlign: "center", marginBottom: "30px" }}>معلومات عنا</h2>
      <p style={{ textAlign: "justify" }}>
        متجر واصل هو حل متمايز للتجارة الإلكترونية يوفر منصة إلكترونية ،
        اكسسوارات المحمول والأجهزة الذكية ، تقديم التجارة الإلكترونية ، والاتصال
        بالعلامات التجارية ، والمدفوعات كتمويل صغير ، والوفاء بالميل الأخير إلى
        العملاء. إنها الوجهة النهائية للتسوق عبر الإنترنت في قطر تقديم تجربة
        تسوق خالية تمامًا من المتاعب من خلال بوابات آمنة وموثوق بها. نقدم لك
        تسوقًا عصريًا وموثوقًا به مع جميع العلامات التجارية المفضلة لديك وأكثر
        من ذلك. الآن التسوق أسهل وأسرع وممتع دائمًا. نحن نساعدك على اتخاذ القرار
        الصحيح هنا. متجر واصل هو أكثر الأنشطة التجارية الصغيرة ملاءمة للعملاء
        على الإنترنت لبيع مجموعة واسعة من منتجات أصلية للعملاء دون مشاكل. متجر
        واصل يتم تجميعه الشركات الصغيرة في شبكة ، مما يمكّن العملاء من شراء
        منتجاتهم الأساسية من موقعنا. تم إطلاق متجر واصل في مارس 2022. يعرض متجر
        واصل جميع المنتجات فئات مثل الإلكترونية ، ملحقات الهاتف المحمول ، وما
        زال العد! مجموعتنا يجمع بين أحدث اتجاهات الموضة بالإضافة إلى المفضلات
        على الإطلاق. منتجاتنا تم اختيارها خصيصًا لك. لدينا في متجر واصل كل ما
        تحتاجه تحت مظلة واحدة. نحن نقدم لعملائنا تجارب تسوق عبر الإنترنت لا
        تنسى. مكرسة لدينا يعمل فريق ضمان الجودة في متجر واصل على مدار الساعة
        للتأكد شخصيًا من صحة المنتج تصل الحزم في الوقت المحدد. يمكنك اختيار ما
        تريد. نحن نقدمها لك عنوان عبر قطر. خدماتنا على عتبة داركم طوال الوقت.
        الحصول على أفضل المنتجات التي تتمتع بأفضل تجربة تسوق عبر الإنترنت في كل
        مرة. سوف تستمتع عبر الإنترنت التسوق هنا
      </p>
    </div>
  );
};

export default AboutUsArabic;
